body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.numeric-theme {
  margin: 10 auto;
  width: 80% !important;
}

.numeric-theme + .simple-keyboard-preview {
  width: 80%;
}

.simple-keyboard.hg-theme-default.numeric-theme
  .hg-button.hg-standardBtn.hg-button-at {
  max-width: none;
}

.hg-theme-default.hg-layout-numeric .hg-button {
  height: 190px !important;
  font-size: 100px;
}

.container {
  margin: 0 !important;
   padding: 0 !important;
}

.pinHeader {
  background-color: #fefefe;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #3e3859;
}